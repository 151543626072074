import React, { useState, useEffect } from "react";

import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

const PasswordChange = (props) => {
  const { data, reloadData, pageRef, setPasswordPanel } = props;
  const [passwordForm, setPasswordForm] = useState();

  function changeDone() {
    reloadData();
    setPasswordPanel(false);
  }

  useEffect(() => {
    if (data) {
      setPasswordForm(
        <FormBuilder
          form={"PasswordChange"}
          action={changeDone}
          formSetup={formSetup}
          pageRef={pageRef}
          formData={data}
        />,
      );
    }
    // eslint-disable-next-line
  }, [data]);

  return <React.Fragment>{passwordForm}</React.Fragment>;
};

export default PasswordChange;
