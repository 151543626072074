import React from "react";
import { injectIntl } from "react-intl";
import "./ScoreQueryButton.scss";

function ScoreQueryButton(props) {
  const { children, onClick, isPending, isDisabled, intl } = props;
  const { formatMessage } = intl;

  if (isPending) {
    return (
      <div className="score-query-button-container">
        <button className="score-query-button score-query-button--pending" disabled>
          <i className="far fa-clock"></i>
        </button>
        <span>
          {formatMessage({
            id: "awaitingResponse",
            defaultMessage: "awaiting response",
          })}
        </span>
      </div>
    );
  }

  return (
    <button className={`score-query-button ${isDisabled ? "disabled" : ""}`} onClick={onClick}>
      {children}
    </button>
  );
}

export default injectIntl(ScoreQueryButton);
