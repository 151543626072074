import Paho from "paho-mqtt";
import { inflate } from "pako";

var connectOptions = {
  useSSL: true,
  timeout: 30000,
  mqttVersion: 4,
  reconnect: false,
  keepAliveInterval: 60,
};

class WebsocketWrapper {
  constructor(url, clientId) {
    this.client = new Paho.Client(
      url.split("/mqtt")[0].replace("wss://", ""),
      443,
      "/mqtt" + url.split("/mqtt")[1],
      clientId,
    );

    this.onMessageArrived = this.onMessageArrived.bind(this);
    this.client.onMessageArrived = this.onMessageArrived;
  }

  connect() {
    const effectiveConnectionData = structuredClone(connectOptions);
    effectiveConnectionData.onSuccess = this.onConnect;
    effectiveConnectionData.onFailure = this.onFailure;
    this.client.connect(effectiveConnectionData);
  }

  disconnect() {
    if (this.client.isConnected()) {
      this.client.disconnect();
    }
  }

  onMessageArrived(message) {
    const parsedMessage = JSON.parse(message.payloadString);

    let data = null;

    if (parsedMessage.compressedData) {
      data = JSON.parse(this.deCompressMessage(parsedMessage.compressedData));
    }

    this.messageProcessor(parsedMessage, data);
  }

  deCompressMessage(message) {
    // Decode base64 string
    const compressed = atob(message);
    // Convert binary string to character array
    const characterDataArray = compressed.split("").map(function (x) {
      return x.charCodeAt(0);
    });
    // Turn character code array into byte-array
    const binaryDataArray = new Uint8Array(characterDataArray);
    // Decompress base64 binary data utf-8 array
    const decompressedData = inflate(binaryDataArray);
    // Convert buffer / byteArray back to ascii string (utf-16)
    const decompressedMessage = new TextDecoder("utf-8").decode(decompressedData);

    return decompressedMessage;
  }

  get isConnected() {
    return this.client.isConnected();
  }

  messageProcessor() {
    console.log("Should be implemented by the data consumer");
  }

  onConnect() {
    console.log("Should be implemented by the data consumer");
  }

  onFailure() {
    console.log("Should be implemented by the data consumer");
  }
}

export default WebsocketWrapper;
