import React, { useState, useEffect } from "react";

import { Collapse } from "reactstrap";
import { injectIntl, FormattedMessage } from "react-intl";

import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

const NewCredentials = (props) => {
  const { intl, pageRef, match, reloadData, competitions, venues, entities } = props;
  const { formatMessage } = intl;

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [formType, setFormType] = useState(null);
  const [newForm, setNewForm] = useState(null);
  const toggleForm = () => setIsFormOpen(!isFormOpen);
  const [, updateAllState] = React.useState();
  const forceUpdate = React.useCallback(() => updateAllState({}), []);

  useEffect(() => {
    if (venues.data && entities.data && competitions.data) {
      let now = new Date();
      let formData = {
        organizationId: match.params.organizationId,
        type: formType,
        startDate: now.toISOString().split("T")[0],
        competitions: competitions.data.data,
        venues: venues.data.data,
        entities: entities.data.data,
      };
      let formName = null;
      switch (formType) {
        case "ENTITY":
          formName = "NewUserEntity";
          break;
        case "ALL":
          formName = "NewUserAll";
          break;
        case "VENUE":
          formName = "NewUserVenue";
          break;
        default:
          break;
      }
      if (formName) {
        setNewForm(
          <FormBuilder
            form={formName}
            action={reloadData}
            formSetup={formSetup}
            pageRef={pageRef}
            formData={formData}
          />,
        );
      }
      forceUpdate();
    }
    // eslint-disable-next-line
  }, [formType, venues.data, entities.data, competitions.data]);

  function changeType(e) {
    setFormType(e.target.value);
  }

  return (
    <React.Fragment>
      <h4 onClick={toggleForm}>
        {!isFormOpen && (
          <span>
            <FormattedMessage id="user.add" defaultMessage="Add New User" /> <i className="fas fa-caret-down"></i>
          </span>
        )}
        {isFormOpen && (
          <span>
            <FormattedMessage id="form.hide" defaultMessage="Hide Form" /> <i className="fas fa-caret-up"></i>
          </span>
        )}
      </h4>
      <Collapse isOpen={isFormOpen}>
        User Type:{" "}
        <select onChange={changeType}>
          <option value="">
            {formatMessage({
              id: "user.type.select",
              defaultMessage: "Select a user type",
            })}
          </option>
          <option value="ENTITY">
            {formatMessage({
              id: "user.type.team",
              defaultMessage: "Team User",
            })}
          </option>
          <option value="VENUE">
            {formatMessage({
              id: "user.type.venue",
              defaultMessage: "Venue User",
            })}
          </option>
          <option value="ALL">
            {formatMessage({
              id: "user.type.all",
              defaultMessage: "Super User",
            })}
          </option>
        </select>
        <br />
        {formType !== null && newForm}
        <hr />
        <br />
      </Collapse>
    </React.Fragment>
  );
};

export default injectIntl(NewCredentials);
