import { useEffect, useState } from "react";
import { APP_API_URL } from "../../../config/Environment";
import { useToken } from "./token";

export const usePolling = (input, interval) => {
  const { token, LiveToken } = useToken();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    const func = async () => {
      let url = APP_API_URL + input;
      const data = await getData(url);
      if (typeof data !== "undefined") {
        setData(data);
      }
    };

    const intervalId = setInterval(func, interval);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line
  }, [input, token]);

  const getData = async function (url) {
    await LiveToken().validateToken();
    if (input && token) {
      setLoading(true);
      let tokenType =
        token.tokenType.charAt(0).toUpperCase() +
        token.tokenType.substr(1).toLowerCase();

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: tokenType + " " + token.token,
          },
          signal: signal,
        }).then((body) => body.json());

        if (response.data) {
          setLoading(false);
          return response;
        } else {
          console.log("error", response);
          setError(response.message);
          setLoading(false);
        }
      } catch (e) {
        console.log("E", e);
      }
    }
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return { error, loading, data };
};
