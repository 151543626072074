import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { useFetch } from "../Hooks/fetch";

const LookupField = (props) => {
  const { lookup, formData, defaultValue, match } = props;
  const [lookupData, setLookupData] = useState([]);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [endPoint, setEndPoint] = useState(null);

  const { error, loading, data } = useFetch("/" + endPoint, "", refreshFlag, "location", true);

  useEffect(() => {
    let endpoint = lookup.endpoint;
    if (lookup.parameters) {
      lookup.parameters.forEach((parameter, key) => {
        endpoint = endpoint.replace("#" + key, formData[parameter]);
      });
    }
    endpoint = endpoint.replace("#sport", match.params.sport);
    setEndPoint(endpoint);
    setRefreshFlag(true);
    // eslint-disable-next-line
  }, [lookup]);

  useEffect(() => {
    if (data) {
      let array = data !== null ? data.data : [];
      setLookupData(
        array.sort(function (a, b) {
          var aMixed = a[lookup.text];
          var bMixed = b[lookup.text];
          return aMixed < bMixed ? -1 : 1;
        }),
      );
    }
  }, [data, lookup.text]);

  return (
    <React.Fragment>
      <option value={null}>-</option>
      {!loading &&
        lookupData &&
        lookupData.map((option, index) => (
          <option
            value={option[lookup.value]}
            key={option[lookup.value]}
            selected={defaultValue === option[lookup.value] ? true : false}
          >
            {option[lookup.text]}
          </option>
        ))}
      {error}
    </React.Fragment>
  );
};

export default withRouter(LookupField);
