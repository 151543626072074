import { useEffect, useState } from "react";
import { useAuth0 } from "../../../Auth/react-auth0-wrapper";
import { TOKEN_API_URL } from "../../../config/Environment";
import defaultSetupConfiguration from "../../../config/defaultSetupConfiguration";

export const useToken = () => {
  const [token, setToken] = useState(null);
  const [refreshTokenVersion, setRefreshTokenVersion] = useState(0);
  const [authToken, setAuthToken] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const { readBody = (body) => body.json() } = {};
  const { getTokenSilently, loading } = useAuth0();
  const productCode = defaultSetupConfiguration.options.productCode;
  const abortController = new AbortController();
  const signal = abortController.signal;

  const [isFetching, setIsFetching] = useState(false);
  const updateRevision = () => setRefreshTokenVersion(refreshTokenVersion + 1);

  const tokenName = defaultSetupConfiguration.options.subProduct
    ? defaultSetupConfiguration.options.subProduct
        .replace(/\s/g, "_")
        .toLowerCase() +
      "_" +
      productCode +
      "_tokenDetails"
    : productCode + "_tokenDetails";

  useEffect(() => {
    // localStorage.clear();
    let tokenDetails =
      localStorage.getItem(tokenName) !== null
        ? JSON.parse(localStorage.getItem(tokenName))
        : null;
    if (!checkExpiry(tokenDetails.expiry)) {
      //Get New Token
      setCustomerId(tokenDetails.customerId);
      updateRevision();
    } else {
      setToken(tokenDetails);
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      if (!loading && refreshTokenVersion) {
        try {
          const authToken = await getTokenSilently();
          setAuthToken(authToken);
        } catch (e) {
          console.log("EE (getToken):", e);
        }
      }
    })(); // eslint-disable-next-line
  }, [refreshTokenVersion, loading]);

  useEffect(() => {
    (async () => {
      if (refreshTokenVersion && authToken) {
        let url = TOKEN_API_URL + "/v1/products/token";
        try {
          let values = {
            userIdentityToken: authToken,
            product: productCode,
            customerId: String(customerId),
          };
          setIsFetching(true);
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            signal: signal,
            body: JSON.stringify(values),
          });
          if (response.ok) {
            const body = await readBody(response);
            let expiryDelta = body.data.expiresIn * 1000;
            let expiry = expiryDelta + Date.now();
            let token = {
              token: body.data.token,
              tokenType: body.data.tokenType,
              expiry: expiry,
              customerId: customerId,
            };
            setToken(token);
            localStorage.setItem(tokenName, JSON.stringify(token));
          } else {
            const body = await readBody(response);
            console.log("error", body);
          }
        } catch (e) {
          console.log("E", e);
        } finally {
          setIsFetching(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [refreshTokenVersion, authToken]);

  function checkExpiry(tokenDate) {
    if (parseInt(tokenDate) > Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * An extension upon the static nature of the hook,
   * use this to keep the token expiry updated at all times
   * for cases like Dashboard
   */
  function LiveToken() {
    let tokenDetails =
      localStorage.getItem(tokenName) !== null
        ? JSON.parse(localStorage.getItem(tokenName))
        : null;

    return {
      async validateToken() {
        if (!checkExpiry(tokenDetails.expiry) && !isFetching) {
          setCustomerId(tokenDetails.customerId);
          updateRevision();
        }
        return tokenDetails;
      },
    };
  }

  return { token, LiveToken };
};
