import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import config from "./Auth/auth_config.json";
import { Auth0Provider } from "./Auth/react-auth0-wrapper";
import { GetVar } from "./Components/Base/Extras/Utilities/GetQueryVariable";
import "./index.scss";
import messagesDE from "./translations/de.json";
import messagesEN from "./translations/en.json";
import messagesES from "./translations/es.json";
import messagesFR from "./translations/fr.json";

const messages = {
  en: messagesEN,
  es: messagesES,
  de: messagesDE,
  fr: messagesFR,
};

let locale = GetVar("locale") !== "" ? GetVar("locale") : "en-GB";
if (localStorage.getItem("atrium_locale") !== null) {
  locale = localStorage.getItem("atrium_locale");
}
const language =
  locale !== "" ? locale.split(/[-_]/)[0] : navigator.language.split(/[-_]/)[0];

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <IntlProvider locale={locale} messages={messages[language]}>
      <BrowserRouter>
        <App language={language} locale={locale} className="App" />
      </BrowserRouter>
    </IntlProvider>
  </Auth0Provider>,
  document.getElementById("root")
);
