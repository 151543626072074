import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory, withRouter } from "react-router-dom";
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { BASE_DOMAIN_URL } from "../../../config/Environment";
import { CheckScope } from "../Extras/Utilities/CheckScope";
import "./MenuIcons.scss";

const MenuBuilder = (props) => {
  const { menuItems, isOpen, color, expand, data, light, collapse, token } =
    props;
  const history = useHistory();
  const [menuData, setMenuData] = useState(null);

  useEffect(() => {
    let currentPath = props.history.location.pathname;
    let menus = [];
    menuItems.map((group) => {
      let menuList = group.items.map((item, index) => {
        let show = evaluateConditions(item.conditions);
        let link = buildLink(item.link, item.variables);
        let clickFunction = item.function ? props.data[item.function] : null;
        let menuComponent = item.component ? props.data[item.component] : null;
        let activeClass =
          currentPath && currentPath.indexOf(link) === 0 ? "active" : "";
        let external = link.indexOf("http") !== -1 ? true : false;
        if (show) {
          if (item.children && item.children.length > 0) {
            return (
              <UncontrolledDropdown nav inNavbar key={index}>
                <DropdownToggle
                  nav
                  caret
                  className={activeClass}
                  onClick={() => {
                    if (!isOpen) {
                      history.push(link);
                    }
                  }}
                >
                  {!menuComponent && (
                    <React.Fragment>
                      {item.icon && <i className={item.icon} />}
                      {!item.translation && <span>{item.text}</span>}
                      {item.translation && (
                        <span>
                          <FormattedMessage
                            id={item.translation}
                            defaultMessage={item.text}
                          />
                        </span>
                      )}
                    </React.Fragment>
                  )}
                  {menuComponent}
                </DropdownToggle>
                <DropdownMenu>
                  {item.children.map((item, childIndex) => {
                    let showChild = evaluateConditions(item.conditions);
                    let external =
                      item.link.indexOf("http") !== -1 ? true : false;
                    let childLink = buildLink(item.link, item.variables);
                    let childActiveClass =
                      currentPath && currentPath.indexOf(item.link) === 0
                        ? "active-child"
                        : "";
                    if (showChild) {
                      if (item.text !== "divider") {
                        if (!external && !clickFunction && !menuComponent) {
                          return (
                            <Link
                              to={childLink}
                              key={childIndex}
                              className={childActiveClass}
                            >
                              <DropdownItem>
                                {item.icon && <i className={item.icon} />}
                                <span>
                                  {!item.translation && item.text}
                                  {item.translation && (
                                    <FormattedMessage
                                      id={item.translation}
                                      defaultMessage={item.text}
                                    />
                                  )}
                                </span>
                              </DropdownItem>
                            </Link>
                          );
                        } else if (clickFunction) {
                          return (
                            <span
                              key={index}
                              className="nav-link"
                              onClick={() => clickFunction({})}
                            >
                              {item.icon && <i className={item.icon} />}
                              <span>
                                {!item.translation && item.text}
                                {item.translation && (
                                  <FormattedMessage
                                    id={item.translation}
                                    defaultMessage={item.text}
                                  />
                                )}
                              </span>
                            </span>
                          );
                        } else {
                          return (
                            <a
                              key={childIndex}
                              href={link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <DropdownItem>
                                {item.icon && <i className={item.icon} />}
                                <span>
                                  {!item.translation && item.text}
                                  {item.translation && (
                                    <FormattedMessage
                                      id={item.translation}
                                      defaultMessage={item.text}
                                    />
                                  )}
                                </span>
                              </DropdownItem>
                            </a>
                          );
                        }
                      } else {
                        return <DropdownItem divider key={childIndex} />;
                      }
                    } else {
                      return null;
                    }
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
            );
          } else {
            return (
              <NavItem key={index}>
                {!external && !clickFunction && !menuComponent && (
                  <Link to={link} className={"nav-link " + activeClass}>
                    {item.icon && <i className={item.icon} />}
                    <span>
                      {!item.translation && item.text}
                      {item.translation && (
                        <FormattedMessage
                          id={item.translation}
                          defaultMessage={item.text}
                        />
                      )}
                    </span>
                  </Link>
                )}
                {clickFunction && (
                  <span
                    key={index}
                    className="nav-link"
                    onClick={() => clickFunction({})}
                  >
                    {item.icon && <i className={item.icon} />}
                    <span>
                      {!item.translation && item.text}
                      {item.translation && (
                        <FormattedMessage
                          id={item.translation}
                          defaultMessage={item.text}
                        />
                      )}
                    </span>
                  </span>
                )}
                {menuComponent}
                {external && (
                  <a
                    key={index}
                    href={link}
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.icon && <i className={item.icon} />}
                    <span>
                      {!item.translation && item.text}
                      {item.translation && (
                        <FormattedMessage
                          id={item.translation}
                          defaultMessage={item.text}
                        />
                      )}
                    </span>
                  </a>
                )}
              </NavItem>
            );
          }
        } else {
          return null;
        }
      });
      menus.push(menuList);
      return null;
    });

    let menuGroups = menus.map((menu, index) => {
      return (
        <Nav key={index} navbar>
          {menu}
        </Nav>
      );
    });
    setMenuData(menuGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function evaluateConditions(conditionList) {
    let show = true;
    let conditions = [];
    if (conditionList) {
      conditionList.forEach((condition) => {
        if (condition.variable !== "hasScope") {
          let conditionValue = condition.key
            ? data[condition.variable][condition.key]
            : data[condition.variable];
          let result =
            Boolean(conditionValue) === condition.evaluator ? true : false;
          conditions.push(result);
        } else {
          conditions.push(CheckScope(token, condition.value));
        }
      });
      conditions.forEach((result) => {
        show = show & result;
      });
    }
    return show;
  }

  function buildLink(link, variables) {
    if (variables) {
      variables.forEach((value, index) => {
        link = link.replace("%" + index, data[value]);
      });
    }
    if (link.indexOf("%BASEDOMAIN%")) {
      link = link.replace("%BASEDOMAIN%", BASE_DOMAIN_URL);
    }
    return link;
  }

  return (
    <Navbar
      color={color}
      light={light}
      expand={expand}
      className={props.className}
    >
      <Container fluid>
        {collapse && (
          <Collapse isOpen={isOpen} navbar>
            {menuData}
          </Collapse>
        )}
        {!collapse && <React.Fragment>{menuData}</React.Fragment>}
      </Container>
    </Navbar>
  );
};

export default withRouter(MenuBuilder);
