import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import qs from "qs";
import { useFetch } from "../../Base/Hooks/fetch";
import Filter from "../components/Filter";
import ScoreQueryEvent from "../components/ScoreQueryEvent";
import { usePolling } from "../../Base/Hooks/polling";
import FlashMessage from "../../Base/PageDisplay/FlashMessage";
import "./ScoreQuery.scss";

const API_ENDPOINT = "/v1/dashboard";

function ScoreQuery(props) {
  const { intl } = props;
  const { formatMessage } = intl;
  const [errorMessage, setErrorMessage] = useState("");
  const [query, setQuery] = useState(API_ENDPOINT);
  const [selectedFixtures, setSelectedFixture] = useState([]);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const { data: notificationData } = usePolling("/v1/basketball/stream_notifications", 2000);

  const { data } = useFetch(query);
  const [filters, setFilters] = useState({
    freeText: "",
    sport: "",
  });

  const fixtures = getFilteredData({
    fixtures: data?.data ?? [],
    query: filters.freeText,
    selectedFixtureIds: showSelectedOnly ? selectedFixtures : null,
  });

  const onSelectedFixturesChange = (fixtureId) => {
    const existingIndex = selectedFixtures.findIndex((id) => id === fixtureId);

    if (existingIndex > -1) {
      if (selectedFixtures.length === 1) {
        setShowSelectedOnly(false);
      }

      setSelectedFixture([...selectedFixtures.slice(0, existingIndex), ...selectedFixtures.slice(existingIndex + 1)]);
      return;
    }

    if (selectedFixtures.length < 5) {
      setSelectedFixture([...selectedFixtures, fixtureId]);
    } else {
      setErrorMessage(
        formatMessage({
          id: "maxWatchGamesLimit",
          defaultMessage: "You can't subscribe to more than 5 games",
        }),
      );
    }
  };

  useEffect(() => {
    var query = qs.stringify(
      {
        sport: filters.sport ? filters.sport : null,
        freeText: null,
      },
      { skipNulls: true },
    );

    setQuery(`${API_ENDPOINT}?${query}`);
  }, [filters.sport]);

  return (
    <div className="score-query-page">
      {errorMessage && (
        <FlashMessage
          item={{
            type: "danger",
            message: errorMessage,
            isOpen: true,
          }}
          removeFlash={() => setErrorMessage("")}
        />
      )}{" "}
      <h2 className="score-query-page__title">
        {formatMessage({
          id: "liveGameQuery",
          defaultMessage: "Name",
        })}
      </h2>
      <div className="score-query__event-list">
        <Filter
          onChange={setFilters}
          value={filters}
          showSelectedOnly={showSelectedOnly}
          setShowSelectedOnly={(showOnly) => setShowSelectedOnly(showOnly)}
        />
        {fixtures.map((fixture) => (
          <ScoreQueryEvent
            key={fixture.fixtureId}
            fixture={fixture}
            notificationData={notificationData?.data}
            isSelected={selectedFixtures.includes(fixture.fixtureId)}
            onSelected={onSelectedFixturesChange}
            onError={(msg) => setErrorMessage(msg)}
          />
        ))}
      </div>
    </div>
  );
}

const getFilteredData = ({ fixtures, query, selectedFixtureIds }) => {
  if (!query && !selectedFixtureIds) {
    return fixtures;
  }

  const selectedFixtures = selectedFixtureIds
    ? fixtures.filter((fixture) => selectedFixtureIds.includes(fixture.fixtureId))
    : fixtures;

  return selectedFixtures.filter((fixture) =>
    fixture.competitors.some((competitor) => competitor.name.toLowerCase().includes(query.toLowerCase())),
  );
};

export default injectIntl(ScoreQuery);
