import WebsocketWrapper from "./Websocket";

class LiveScoreFeed extends WebsocketWrapper {
  constructor(connectionData) {
    super(connectionData.url, connectionData.clientId);
    this.connectionData = connectionData;

    this.onConnect = this.onConnect.bind(this);
    this.onFailure = this.onFailure.bind(this);
  }

  onConnect() {
    this.client.subscribe(this.connectionData.topics.find((topic) => topic.scope === "read:stream_statistics").topic);
    this.client.subscribe(
      this.connectionData.topics.find((topic) => topic.scope === "read:stream_statistics_catchup").topic,
    );
  }

  onFailure() {
    this.onFailureCallback();
  }

  registerConnectionFailureHandler(cb) {
    this.onFailureCallback = cb;
  }

  registerMessageListener(cb) {
    this.onMessageCallback = cb;
  }

  messageProcessor(message, data) {
    if (message.type === "statistics") {
      const normalizedData = this.processStatisticsMessage(data);
      this.notifyConsumer(normalizedData);
    }
  }

  processStatisticsMessage(data) {
    return Object.keys(data).reduce((soFar, curr) => {
      const currentEntityData = data[curr];
      return {
        ...soFar,
        [curr]: currentEntityData.entity.points,
      };
    }, {});
  }

  notifyConsumer(payload) {
    this.onMessageCallback(payload);
  }
}

export default LiveScoreFeed;
