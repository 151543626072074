import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import "./Filter.scss";

const sports = ["basketball", "ice3", "hockey"];

function Filter({ value, onChange, showSelectedOnly, setShowSelectedOnly, intl }) {
  const { formatMessage } = intl;
  const onFilterChange = (prop, val) => {
    onChange({
      ...value,
      [prop]: val,
    });
  };

  return (
    <div className="event-log-filter">
      <input
        className="event-log-filter-input"
        placeholder={formatMessage({
          id: "teamName",
          defaultMessage: "Team name...",
        })}
        onChange={(ev) => onFilterChange("freeText", ev.target.value)}
        value={value.freeText}
      />
      <select
        className="event-log-filter-input"
        value={value.sport}
        onChange={(ev) => onFilterChange("sport", ev.target.value)}
      >
        <option value="">
          {formatMessage({
            id: "allSports",
            defaultMessage: "All sports",
          })}
        </option>
        {sports.map((sport) => (
          <option key={sport} value={sport}>
            {sport}
          </option>
        ))}
      </select>
      <label className="score-query-checkbox">
        <input type="checkbox" checked={showSelectedOnly} onChange={() => setShowSelectedOnly(!showSelectedOnly)} />
        <span>
          {formatMessage({
            id: "showSelectedOnly",
            defaultMessage: "Show selected only",
          })}
        </span>
      </label>
    </div>
  );
}

export default injectIntl(Filter);
