import React, { useState, useEffect, useMemo } from "react";
import "./MultiSelect.scss";

const MultiSelect = (props) => {
  const { field, formData, form } = props;
  const [selectedRows, setSelectedRows] = useState(formData[field.initial] ? formData[field.initial] : []);
  const [query, setQuery] = useState("");

  useEffect(() => {
    form.setFieldValue(field.name, selectedRows);
    // eslint-disable-next-line
  }, [selectedRows]);

  const visibleOptions = useMemo(() => {
    const allOptions = formData[field.data].sort(function (a, b) {
      var aMixed = a["name"];
      var bMixed = b["name"];
      return aMixed < bMixed ? -1 : 1;
    });

    const withoutAlreadySelectedOptions = allOptions.filter(
      (rowData) => selectedRows.indexOf(rowData[field.valueField]) === -1,
    );

    if (!query) {
      return withoutAlreadySelectedOptions;
    }

    return withoutAlreadySelectedOptions.filter((option) => option.name.toUpperCase().includes(query.toUpperCase()));
  }, [formData, query, selectedRows]);

  const removeItem = (item) => setSelectedRows(selectedRows.filter((row) => row !== item[field.valueField]));

  return (
    <React.Fragment>
      <div className="multi-select">
        <div className="multi-select-all">
          <input placeholder="Search..." value={query} onChange={(ev) => setQuery(ev.target.value)} />
          <ul className="multi-select-list">
            {visibleOptions.map((item) => (
              <li
                key={`add-${item[field.valueField]}`}
                onClick={() => setSelectedRows([...selectedRows, item[field.valueField]])}
                title={item.name}
              >
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="multi-select-selected">
          <span className="multi-select-text">{selectedRows.length} item(s) selected</span>
          <ul className="multi-select-list">
            {formData[field.data]
              .filter((rowData) => selectedRows.indexOf(rowData[field.valueField]) !== -1)
              .sort(function (a, b) {
                var aMixed = a["name"];
                var bMixed = b["name"];
                return aMixed < bMixed ? -1 : 1;
              })
              .map((item) => (
                <li key={`remove-${item[field.valueField]}`} onClick={() => removeItem(item)}>
                  <span>{item.name}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultiSelect;
