import { useEffect, useState } from "react";
import { APP_API_URL } from "../../../config/Environment";
import { useToken } from "./token";
const defaultOpts = { method: "GET", values: null };

export const useFetch = (
  input,
  opts = defaultOpts,
  refreshFlag = true,
  location = "location",
  all = false
) => {
  const { token, LiveToken } = useToken();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { readBody = (body) => body.json() } = opts;
  const abortController = new AbortController();
  const signal = abortController.signal;

  useEffect(() => {
    (async () => {
      let url = APP_API_URL + input;
      const allData = await getAllData(url);
      if (typeof allData !== "undefined") {
        setData(allData);
      }
    })();
    // eslint-disable-next-line
  }, [input, opts, refreshFlag, token]);

  const getData = async function (url) {
    await LiveToken().validateToken();
    if (input && refreshFlag && token) {
      setLoading(true);
      let tokenType =
        token.tokenType.charAt(0).toUpperCase() +
        token.tokenType.substr(1).toLowerCase();
      try {
        const response = await fetch(url, {
          method: opts.method,
          headers: {
            Authorization: tokenType + " " + token.token,
          },
          signal: signal,
          ...(opts?.values && { body: JSON.stringify(opts.values) }),
        });
        if (response.ok) {
          const body = await readBody(response);
          setLoading(false);
          return body;
        } else {
          const body = await readBody(response);
          console.log("error", body);
          setError(body.message);
          setLoading(false);
        }
      } catch (e) {
        console.log("E", e);
      }
    }
  };

  const getAllData = async function (url) {
    try {
      const results = await getData(url);
      if (results) {
        if (results.links && results.links.next && all) {
          return combineData(results, await getAllData(results.links.next));
        } else {
          return results;
        }
      }
    } catch (e) {
      console.log("E", e);
    }
  };

  function combineData(currentData, addedData) {
    let data = currentData.data.concat(addedData.data);
    currentData.data = data;
    return currentData;
  }

  useEffect(() => {
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return { error, loading, data };
};
