import React from "react";
import "./ScoreQueryNotification.scss";

function ScoreQueryNotification(props) {
  const { onClick, badgeCount } = props;

  return (
    <button className={`score-query-notification${badgeCount > 0 ? " active" : ""}`} onClick={onClick}>
      {badgeCount > 0 && <span>{badgeCount}</span>}
      <i className="fas fa-bell"></i>
    </button>
  );
}

export default ScoreQueryNotification;
