const menuItems = [
  {
    items: [
      {
        link: "/organizations",
        text: "Organizations",
        translation: "organizations",
        conditions: [
          { variable: "isAuthenticated", evaluator: true },
          { variable: "hasAccess", evaluator: true },
        ],
        children: [],
      },
    ],
  },
  {
    items: [
      {
        link: "#",
        text: "Login",
        icon: "fas fa-sign-in-alt",
        function: "login",
        conditions: [{ variable: "isAuthenticated", evaluator: false }],
      },
      {
        link: "#",
        text: "UserProfile",
        component: "userprofile",
        conditions: [{ variable: "isAuthenticated", evaluator: true }],
      },
      {
        link: "#",
        text: "LanguageSelect",
        component: "languageselect",
      },
      {
        link: "#",
        text: "",
        icon: "fas fa-sign-out-alt",
        function: "logout",
        conditions: [{ variable: "isAuthenticated", evaluator: true }],
      },
    ],
  },
];

export default menuItems;
