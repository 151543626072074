import React, { useEffect, useState } from "react";
import { useIMask } from "react-imask";
import { injectIntl } from "react-intl";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ScoreQueryButton from "./ScoreQueryButton";
import "./ScoreQueryEvent.scss";

const periods = [1, 2, 3, 4, 11, 12, 13, 14, 15, 16];

const sharedEventTypes = [
  "2pt::made",
  "2pt::missed",
  "3pt::made",
  "3pt::missed",
  "freeThrow::made",
  "freeThrow::missed",
];

const eventTypes = [...sharedEventTypes, "missingEvent"];

const changeEventToTypes = [...sharedEventTypes, "otherTeam"];

/*
 * props:
    competitors: Array<{
      "isHome": boolean,
      "entityId": string;
      "name": string;
      "colors": {},
      "logo": string;
    }>;
    onSubmit: (data: any) => void;
    onClose: () => void;
 */
function EventQueryModal(props) {
  const { competitors, onSubmit, onClose, intl } = props;
  const { formatMessage } = intl;
  const [periodId, setPeriodId] = useState("");
  const [eventType, setEventType] = useState("");
  const [team, setTeam] = useState("");
  const [changeTo, setChangeTo] = useState("");
  const [comments, setComments] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const {
    ref,
    value: clock,
    setValue,
  } = useIMask({
    mask: "00:00",
  });

  const onUpdateTime = (ev) => {
    const { value } = ev.target;

    if (!value) {
      return;
    }

    ensureClockRangeIsValid(value);
  };

  const formatTime = (time) => {
    const [minutes, seconds] = time.split(":").map((num) => parseInt(num, 10));

    return `PT${minutes}M${seconds}S`;
  };

  const ensureClockRangeIsValid = (time) => {
    if (time.length !== 5) {
      setErrorMessage("");
      setErrorMessage(
        formatMessage({
          id: "invalidTimeFormat",
          defaultMessage: "Invalid time format",
        }),
      );
      return;
    }

    const [minutes, seconds] = time.split(":").map((num) => parseInt(num, 10));

    if (minutes > 12 || seconds > 60) {
      setErrorMessage(
        formatMessage({
          id: "invalidClockRange",
          defaultMessage: "Invalid clock range",
        }),
      );
      return false;
    }

    if (minutes === 12 && seconds > 0) {
      setErrorMessage("");
      setErrorMessage(
        formatMessage({
          id: "invalidClockRange",
          defaultMessage: "Invalid clock range",
        }),
      );
      return false;
    }

    setErrorMessage("");
    return true;
  };

  const validateAndSubmit = () => {
    if (!periodId || !eventType || !team || !clock) {
      setErrorMessage(
        formatMessage({
          id: "pleaseReviewForm",
          defaultMessage: "Please review the form",
        }),
      );
      return;
    }

    if (!ensureClockRangeIsValid(clock)) {
      return;
    }

    onSubmit({
      periodId,
      eventType,
      entityId: team,
      clock: formatTime(clock),
      changeTo,
      comments,
    });
  };

  // no need of sanitization, because React does it for us:
  // https://reactjs.org/docs/introducing-jsx.html#jsx-prevents-injection-attacks
  const onCommentChange = (ev) => {
    const { value } = ev.target;

    if (value.length <= 200) {
      setComments(value);
    }
  };

  const isButtonEnabled = periodId && eventType && team && clock;

  return (
    <Modal isOpen backdrop keyboard className="event-query-modal">
      <ModalHeader toggle={onClose}>
        <span className="event-modal-title">
          {formatMessage({
            id: "eventQuery",
            defaultMessage: "Event query",
          })}
        </span>
        <p>
          {formatMessage({
            id: "eventQueryDesc",
            defaultMessage: "Input event data to send query to statistician",
          })}
        </p>
      </ModalHeader>
      <ModalBody>
        <div>
          <label>
            {formatMessage({
              id: "period",
              defaultMessage: "Period",
            })}
          </label>
          <select className="event-log-filter-input" value={periodId} onChange={(ev) => setPeriodId(ev.target.value)}>
            <option value="">---</option>
            {periods.map((period) => (
              <option key={period} value={period}>
                {formatMessage({
                  id: `period.${period}`,
                  defaultMessage: "Period",
                })}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>
            {formatMessage({
              id: "time",
              defaultMessage: "Time",
            })}
          </label>
          <input
            ref={ref}
            onBlur={onUpdateTime}
            value={clock}
            onChange={(ev) => setValue(ev.target.value)}
            placeholder={formatMessage({
              id: "timeFormat",
              defaultMessage: "Format: 01:04",
            })}
          />
        </div>
        <div>
          <label>
            {formatMessage({
              id: "eventType",
              defaultMessage: "Event Type",
            })}
          </label>
          <select className="event-log-filter-input" value={eventType} onChange={(ev) => setEventType(ev.target.value)}>
            <option value="">---</option>
            {eventTypes.map((eventType) => (
              <option key={eventType} value={eventType}>
                {formatMessage({
                  id: `eventType.${eventType}`,
                  defaultMessage: "EventType",
                })}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>
            {formatMessage({
              id: "team",
              defaultMessage: "Team",
            })}
          </label>
          <select className="event-log-filter-input" value={team} onChange={(ev) => setTeam(ev.target.value)}>
            <option value="">---</option>
            {competitors.map((team) => (
              <option key={team.entityId} value={team.entityId}>
                {team.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>
            {formatMessage({
              id: "changeTo",
              defaultMessage: "Change To",
            })}
          </label>
          <select className="event-log-filter-input" value={changeTo} onChange={(ev) => setChangeTo(ev.target.value)}>
            <option value="">---</option>
            {changeEventToTypes.map((eventType) => (
              <option key={`changeTo-${eventType}`} value={eventType}>
                {formatMessage({
                  id: `eventType.${eventType}`,
                  defaultMessage: "EventType",
                })}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>
            {formatMessage({
              id: "additionalComments",
              defaultMessage: "additionalComments",
            })}
          </label>
          <textarea
            placeholder={formatMessage({
              id: "charLimit",
              defaultMessage: "Character limit: 200",
            })}
            className="event-log-filter-input"
            value={comments}
            onChange={onCommentChange}
          ></textarea>
        </div>
        {errorMessage && <p className="event-query-modal-error">{errorMessage}</p>}
      </ModalBody>
      <ModalFooter>
        <ScoreQueryButton onClick={validateAndSubmit} isDisabled={!isButtonEnabled || errorMessage}>
          {formatMessage({
            id: "sendQuery",
            defaultMessage: "Send Query",
          })}
        </ScoreQueryButton>
      </ModalFooter>
    </Modal>
  );
}

export default injectIntl(EventQueryModal);
