const menuItems = [
  {
    items: [
      {
        link: "http://synergysports.com/contact/",
        text: "Support",
        translation: "support",
      },
      {
        link: "http://synergysports.com/terms/",
        text: "Terms",
        translation: "terms",
      },
    ],
  },
];

export default menuItems;
