import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import PasswordChange from "./PasswordChange";
import UpdateCredentials from "./UpdateCredentials";
import Modal from "../Base/Modal/Modal";
import { useDelete } from "../Base/Hooks/delete";

const CredentialRow = (props) => {
  const { itemIndex, result, match, venues, entities, competitions, pageRef, setRefreshFlag, intl, reloadData } = props;
  const { formatMessage } = intl;
  const [deleteModalText, setDeleteModalText] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [passwordPanel, setPasswordPanel] = useState(false);
  const [editPanel, setEditPanel] = useState(false);
  const [refreshDeleteData, setRefreshDeleteData] = useState(false);
  const { deleteError, deleteLoading, deleteData } = useDelete(
    "/v1/" +
      match.params.sport +
      "/organizations/" +
      match.params.organizationId +
      "/credentials/" +
      result.credentialId,
    "",
    refreshDeleteData,
  );

  useEffect(() => {
    if (deleteError) {
      pageRef.current.addFlash(
        formatMessage({
          id: "delete.failed",
          defaultMessage: "Delete Failed",
        }),
        +deleteError,
        "danger",
        10,
      );
    } else {
      if (deleteData) {
        pageRef.current.addFlash(
          formatMessage({
            id: "user.delete.success",
            defaultMessage: "User deleted successfully",
          }),
          "success",
          10,
        );
        setRefreshFlag(true);
      }
    }
    setRefreshDeleteData(false);
    setRefreshDeleteData(false);
    // eslint-disable-next-line
  }, [deleteData, deleteError, pageRef]);

  function showDeleteUser(param) {
    setDeleteModalText(
      formatMessage(
        {
          id: "user.delete.confirm",
          defaultMessage: "You are about to delete " + param.name + ". Is this OK?",
        },
        { item: param.name },
      ),
    );
    setDeleteModalOpen(true);
    // setDeleteItemId(param);
  }

  function cancelModal() {
    setDeleteModalOpen(false);
  }

  function deleteUser() {
    setRefreshDeleteData(true);
    setDeleteModalOpen(false);
  }

  return (
    <React.Fragment key={itemIndex}>
      <tr className={"deleting-" + deleteLoading}>
        <td>{result.name}</td>
        <td>{result.username}</td>
        <td>
          {result.credentialType === "ENTITY"
            ? "Team"
            : result.credentialType.slice(0, 1) + result.credentialType.slice(1).toLowerCase()}
        </td>
        <td>{result.expiryDate.split("T")[0]}</td>
        <td className="text-right controls-3">
          <Button outline color="primary" size="sm" onClick={(e) => setEditPanel(!editPanel)}>
            <i className="fas fa-pen"></i>{" "}
            {editPanel ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
          </Button>{" "}
          <Button outline color="secondary" size="sm" onClick={(e) => setPasswordPanel(!passwordPanel)}>
            <i className="fas fa-key"></i>{" "}
            {passwordPanel ? <i className="fas fa-chevron-up" /> : <i className="fas fa-chevron-down" />}
          </Button>{" "}
          <Button outline color="danger" size="sm" onClick={(e) => showDeleteUser(result, e)}>
            <i className="fas fa-minus" />
          </Button>
          <Modal
            isOpen={deleteModalOpen}
            heading={formatMessage({
              id: "user.delete",
              defaultMessage: "Delete User?",
            })}
            text={deleteModalText}
            actionText={formatMessage({
              id: "delete",
              defaultMessage: "Delete",
            })}
            cancelText={formatMessage({
              id: "cancel",
              defaultMessage: "Cancel",
            })}
            action={deleteUser}
            cancel={cancelModal}
          />
        </td>
      </tr>
      {editPanel && (
        <tr>
          <td colSpan="5">
            <UpdateCredentials
              data={result}
              setPasswordPanel={setPasswordPanel}
              reloadData={reloadData}
              pageRef={pageRef}
              competitions={competitions}
              venues={venues}
              entities={entities}
            />
          </td>
        </tr>
      )}
      {passwordPanel && (
        <tr>
          <td colSpan="5">
            <PasswordChange
              data={result}
              setPasswordPanel={setPasswordPanel}
              reloadData={reloadData}
              pageRef={pageRef}
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
};

export default CredentialRow;
