import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Button, Spinner } from "reactstrap";
import { FormattedMessage } from "react-intl";

import { useAccess } from "../Hooks/access";

import "./Login.scss";

const Login = (props) => {
  const { loading, isAuthenticated, setup, setToken, setHasAccess, setProducts, token } = props;
  const [customer, setCustomer] = useState(null);
  const { productToken, customerList, productList } = useAccess(
    loading,
    isAuthenticated,
    setup.options.productCode,
    customer,
    setup.options.subProduct,
  );

  useEffect(() => {
    if (productToken) {
      setToken(productToken);
      setHasAccess(true);
    }
    // eslint-disable-next-line
  }, [productToken]);

  useEffect(() => {
    if (!isAuthenticated) {
      props.login();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if (productList) {
      setProducts(productList);
    }
    // eslint-disable-next-line
  }, [productList]);

  return (
    <React.Fragment>
      {isAuthenticated && !customer && !customerList && !token && <Spinner size="md" color="orange" />}
      {isAuthenticated && customer && !customerList && !token && <Spinner size="md" color="orange" />}
      {customerList && customerList.length === 0 && (
        <Container>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }} className="loginLinkContainer">
              You do not have access to any customers for this product. Please contact support for access.
            </Col>
          </Row>
        </Container>
      )}
      {!customer && customerList && customerList.length > 0 && (
        <Container>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }} className="loginLinkContainer  text-left">
              <h4>
                <FormattedMessage
                  id="selectcustomer"
                  defaultMessage="Select a customer"
                  description="Login Customer Select"
                />
                :
              </h4>
              <div className="tableScroll">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="customerId" defaultMessage="Customer Id" description="Customer Id" />
                      </th>
                      <th>Name</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {customerList.map((result, index) => (
                      <tr key={index}>
                        <td>{result.customerId}</td>
                        <td>{result.name}</td>
                        <td>
                          <Button outline color="secondary" size="sm" onClick={(e) => setCustomer(result.customerId)}>
                            <FormattedMessage id="select" defaultMessage="Select" description="Select Button / Link" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </React.Fragment>
  );
};

export default Login;
