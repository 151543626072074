import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { APP_API_URL } from "../../../config/Environment";
import LiveScoreFeed from "../../../core/LiveScoreFeed";
import { useFetch } from "../../Base/Hooks/fetch";
import { useToken } from "../../Base/Hooks/token";
import EventQueryModal from "./EventQueryModal";
import ScoreQueryButton from "./ScoreQueryButton";
import "./ScoreQueryEvent.scss";
import ScoreQueryNotification from "./ScoreQueryNotification";
import ScoreQueryNotificationLog from "./ScoreQueryNotificationLog";

/*
 * props:
     fixture = {
      "sport": string;
      "status": string;
      "competitionId": string;
      "competitionName": "string;
      "fixtureId": string;
      "venueId": string;
      "venue": string;
      "timezone": string;
      "season": string;
      "seasonId": string;
      "competitors": Array<{
        "isHome": boolean,
        "entityId": string;
        "name": string;
        "colors": {},
        "logo": string;
      }>;
      "startTimeLocal": string;
      "startTimeUTC": string;
      "organizationId": string;
      "organization": string;
     }
     notificationData: Array<{
      added: string;
      fixture_id: string;
      id: string;
      notification_type: "clock" | "score"
      organization_id: string;
      requester: string;
      response: IResponse;
      sport: string;
      updated: string;
    }>
    isSelected: boolean;
    onSelected: (fixtureId: boolean) => void;
    onError: (msg: string) => string;
 */

const API_ENDPOINT = "/v1/stream_notifications";
const dateFormatPattern = "dd/LL/yyyy HH:mm";

function ScoreQueryEvent(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [seenEvents, setSeenEvents] = useState([]);
  //const [isScoreQueryBeingInitialized, setIsScoreQueryBeingInitialized] = useState(false);
  const [isClockQueryBeingInitialized, setIsClockQueryBeingInitialized] =
    useState(false);
  const [isEventQueryModalOpen, setIsEventQueryModalOpen] = useState(false);
  const [shouldFetchConnectionData, setShouldFetchConnectionData] =
    useState(false);
  const [currentScore, setCurrentScore] = useState(null);
  const [livescoreInstance, setLiveScoreInstance] = useState(null);
  const { fixture, notificationData, isSelected, onSelected, onError, intl } =
    props;
  const { formatMessage } = intl;
  const relatedNotifications = (notificationData ?? []).filter(
    (notification) => notification.fixture_id === fixture.fixtureId
  );
  const { competitors } = fixture;
  const { token } = useToken();
  const url = `${APP_API_URL}${API_ENDPOINT}`;

  // const sendScoreQuery = async () => {
  //   try {
  //     setIsScoreQueryBeingInitialized(true);
  //     await sendRequest("score");
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setIsScoreQueryBeingInitialized(false);
  //   }
  // };
  //

  const { data: connectionData, error: connectionError } = useFetch(
    "/v1/client/sport/basketball/fixtures/" +
      fixture.fixtureId +
      "/admin/connection",
    undefined,
    shouldFetchConnectionData
  );

  const onLiveScoreUpdate = (data) => {
    setCurrentScore(data);
  };

  const sendClockQuery = async () => {
    try {
      setIsClockQueryBeingInitialized(true);
      await sendRequest("clock");
    } catch (err) {
      console.log(err);
    } finally {
      // just wait until the request is added to the state to avoid flashing
      window.setTimeout(() => {
        setIsClockQueryBeingInitialized(false);
      }, 100);
    }
  };

  const sendEventQuery = async (metadata) => {
    try {
      toggleEventQueryModal();
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.token,
        },
        body: JSON.stringify({
          notification_type: "event",
          sport: fixture.sport,
          organization_id: fixture.organizationId,
          fixture_id: fixture.fixtureId,
          requester: "unknown",
          metadata,
        }),
      });
    } catch (err) {
      console.log(err);
      onError(
        formatMessage({
          id: "requestError",
          defaultMessage: "Request failed. Please try again later",
        })
      );
    } finally {
      // just wait until the request is added to the state to avoid flashing
    }
  };

  const toggleEventQueryModal = () => {
    setIsEventQueryModalOpen(!isEventQueryModalOpen);
  };

  const sendRequest = async (type) => {
    try {
      await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.token,
        },
        body: JSON.stringify({
          notification_type: type,
          sport: fixture.sport,
          organization_id: fixture.organizationId,
          fixture_id: fixture.fixtureId,
          requester: "unknown",
        }),
      });
    } catch (err) {
      onError(
        formatMessage({
          id: "requestError",
          defaultMessage: "Request failed. Please try again later",
        })
      );
    }
  };

  useEffect(() => {
    if (connectionData?.data) {
      const liveScoreFeed = new LiveScoreFeed(connectionData.data);
      liveScoreFeed.registerMessageListener(onLiveScoreUpdate);
      liveScoreFeed.registerConnectionFailureHandler(() =>
        onError(
          formatMessage({
            id: "liveScoreFailed",
            defaultMessage: "Could not connect to the live score service",
          })
        )
      );

      liveScoreFeed.connect();

      setLiveScoreInstance(liveScoreFeed);
    }
  }, [connectionData]);

  useEffect(() => {
    if (isSelected) {
      if (!connectionData) {
        setShouldFetchConnectionData(true);
        return;
      }

      if (livescoreInstance && !livescoreInstance.isConnected) {
        livescoreInstance.connect();
      }
    }
  }, [isSelected, connectionData]);

  // disconnect on unmount
  useEffect(
    () => () => {
      if (livescoreInstance?.isConnected) {
        livescoreInstance.disconnect();
      }
    },
    []
  );

  useEffect(() => {
    if (!isSelected && livescoreInstance?.isConnected) {
      livescoreInstance.disconnect();
      setCurrentScore(null);
    }
  }, [isSelected, livescoreInstance]);

  useEffect(() => {
    if (isModalOpen) {
      setSeenEvents(
        relatedNotifications
          .filter((notification) => notification.response)
          .map((notification) => notification.id)
      );
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (connectionError) {
      onError(
        formatMessage({
          id: "requestError",
          defaultMessage: "Request failed. Please try again later",
        })
      );
    }
  }, [connectionError]);

  const scoreEvents = relatedNotifications.filter(
    (notification) => notification.notification_type === "score"
  );
  const clockEvents = relatedNotifications.filter(
    (notification) => notification.notification_type === "clock"
  );
  const eventQueries = relatedNotifications.filter(
    (notification) => notification.notification_type === "event"
  );

  if (competitors?.length !== 2) {
    return null;
  }

  return (
    <div className="score-query__event-outer">
      <label className="score-query-checkbox">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelected(fixture.fixtureId)}
        />
      </label>
      <article className="score-query__event score-query__event--desktop">
        <div className="score-query__event-details">
          <h2 className="score-query__event-details__title">
            {competitors[0].name} vs {competitors[1].name} - {fixture.season}
          </h2>
          <div className="score-query__event-details__venue">
            <span>
              <strong>
                {formatMessage({
                  id: "venue",
                  defaultMessage: "Venue",
                })}{" "}
                <i className="far fa-clock"></i>:
              </strong>
              {format(new Date(fixture.startTimeLocal), dateFormatPattern)}
            </span>
            <span>
              <strong>
                {formatMessage({
                  id: "your",
                  defaultMessage: "Your",
                })}{" "}
                <i className="far fa-clock"></i>:
              </strong>{" "}
              {format(new Date(fixture.startTimeUTC), dateFormatPattern)}
            </span>
          </div>
          <h6 className="score-query__event-details__location">
            <i className="fas fa-map-marker"></i>
            {fixture.venue}
          </h6>
        </div>
        <div className="score-query__event-actions">
          <div className="score-query__event-actions__result">
            <div className="score-query__event-actions__team-name">
              {competitors[0].name}
            </div>
            <div className="score-query__event-actions__logo">
              <img src={competitors[0].logo} />
            </div>

            <div className="score-query__event-actions__score left">
              {currentScore ? currentScore[competitors[0].entityId] : "---"}
            </div>
            <div className="score-query__event-actions__divider" />
            <div className="score-query__event-actions__score right">
              {currentScore ? currentScore[competitors[1].entityId] : "---"}
            </div>
            <div className="score-query__event-actions__logo">
              <img src={competitors[1].logo} />
            </div>
            <div className="score-query__event-actions__team-name">
              {competitors[1].name}
            </div>
            <div className="score-query__event-actions__updates">
              <div>
                <strong>
                  {formatMessage({
                    id: "lastUpdated",
                    defaultMessage: "Last Updated",
                  })}
                </strong>
                <span>
                  {formatMessage({
                    id: "moreThanAnHourAgo",
                    defaultMessage: "More than an hour ago",
                  })}
                </span>
              </div>
              <div>
                <strong>
                  {formatMessage({
                    id: "lastConfirmed",
                    defaultMessage: "Last Confirmed",
                  })}
                </strong>
                <span>
                  {formatMessage({
                    id: "noQuerySent",
                    defaultMessage: "No Query Sent",
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className="score-query__event-actions__buttons">
            <ScoreQueryButton onClick={toggleEventQueryModal}>
              {formatMessage({
                id: "eventQuery",
                defaultMessage: "Event Query",
              })}
            </ScoreQueryButton>
            {/*
            <ScoreQueryButton
              onClick={sendScoreQuery}
              isPending={isScoreQueryBeingInitialized || !!scoreEvents.find((event) => !event.response)}
            >
              Score query
            </ScoreQueryButton>
          */}
            <ScoreQueryButton
              onClick={sendClockQuery}
              isPending={
                isClockQueryBeingInitialized ||
                !!clockEvents.find((event) => !event.response)
              }
            >
              {formatMessage({
                id: "clockQuery",
                defaultMessage: "Clock Query",
              })}
            </ScoreQueryButton>
            <ScoreQueryNotification
              onClick={() => setModalOpen(true)}
              badgeCount={
                relatedNotifications.filter(
                  (notification) => notification.response
                ).length - seenEvents.length
              }
            />
          </div>
        </div>
      </article>
      <article className="score-query__event score-query__event--mobile">
        <div className="score-query__event-header">
          <span>
            {format(new Date(fixture.startTimeUTC), dateFormatPattern)}
          </span>
        </div>
        <div className="score-query__event-body">
          <div className="score-query__event-preview">
            <div className="score-query__event-preview-team">
              <img src={competitors[0].logo} />
              <span>{competitors[0].name}</span>
            </div>
            <div className="score-query__event-preview-team">
              <img src={competitors[1].logo} />
              <span>{competitors[1].name}</span>
            </div>
          </div>
          <div className="score-query__event-preview-buttons">
            <ScoreQueryButton onClick={toggleEventQueryModal}>
              {formatMessage({
                id: "event",
                defaultMessage: "Event",
              })}
            </ScoreQueryButton>
            {/*
            <ScoreQueryButton
              onClick={sendScoreQuery}
              isPending={isScoreQueryBeingInitialized || !!scoreEvents.find((event) => !event.response)}
            >
              Score
            </ScoreQueryButton>
            */}
            <ScoreQueryButton
              onClick={sendClockQuery}
              isPending={
                isClockQueryBeingInitialized ||
                !!clockEvents.find((event) => !event.response)
              }
            >
              {formatMessage({
                id: "clock",
                defaultMessage: "Clock",
              })}
            </ScoreQueryButton>
            <ScoreQueryNotification
              onClick={() => setModalOpen(true)}
              badgeCount={relatedNotifications.length - seenEvents.length}
            />
          </div>
        </div>
      </article>
      <Modal
        isOpen={isModalOpen}
        backdrop
        keyboard
        className="notifications-modal"
      >
        <ModalHeader toggle={() => setModalOpen(!isModalOpen)}>
          {formatMessage({
            id: "notifications",
            defaultMessage: "Notifications",
          })}
        </ModalHeader>
        <ModalBody>
          <div className="notification-blocks">
            <div>
              <h3>
                {formatMessage({
                  id: "scoreNotifications",
                  defaultMessage: "Score notifications",
                })}
              </h3>
              <ul className="notification-blocks-list">
                {scoreEvents.map((notification) => (
                  <ScoreQueryNotificationLog
                    key={notification.id}
                    notification={notification}
                    competitors={competitors}
                  />
                ))}
              </ul>
            </div>
            <div>
              <h3>
                {formatMessage({
                  id: "clockNotifications",
                  defaultMessage: "Clock notifications",
                })}
              </h3>
              <ul className="notification-blocks-list">
                {clockEvents.map((notification) => (
                  <ScoreQueryNotificationLog
                    key={notification.id}
                    notification={notification}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className="event-blocks">
            <div>
              <h3>
                {formatMessage({
                  id: "eventQueries",
                  defaultMessage: "Event queries",
                })}
              </h3>
              <ul className="notification-blocks-list">
                {eventQueries.map((notification) => (
                  <ScoreQueryNotificationLog
                    key={notification.id}
                    competitors={competitors}
                    notification={notification}
                  />
                ))}
              </ul>
            </div>
            <div />
          </div>
        </ModalBody>
        <ModalFooter>
          <span onClick={() => setModalOpen(false)}>Close</span>
        </ModalFooter>
      </Modal>
      {isEventQueryModalOpen && (
        <EventQueryModal
          competitors={competitors}
          onSubmit={sendEventQuery}
          onClose={toggleEventQueryModal}
        />
      )}
    </div>
  );
}

export default injectIntl(ScoreQueryEvent);
