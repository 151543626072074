import React, { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import { Container } from "reactstrap";
import "./App.scss";
import { useAuth0 } from "./Auth/react-auth0-wrapper";
import sideLogo from "./Components/Base/Assets/Atrium-Negative.png";
import Footer from "./Components/Base/Footer/Footer";
import Header from "./Components/Base/Header/Header";
import LanguageSelect from "./Components/Base/LanguageSelect/LanguageSelect";
import Login from "./Components/Base/Login/Login";
import Sidedrawer from "./Components/Base/Sidedrawer/Sidedrawer";
import UserProfile from "./Components/Base/UserProfile/UserProfile";
import Credentials from "./Components/Credentials/Credentials";
import Organizations from "./Components/Organizations/Organizations";
import ScoreQuery from "./Components/ScoreQuery/page/ScoreQuery";
import defaultSetupConfiguration from "./config/defaultSetupConfiguration";

const App = (props) => {
  const { language, locale } = props;
  const { loading, user, isAuthenticated, loginWithRedirect, logout } =
    useAuth0();
  const title = "Synergy Stats Admin";
  const setup = defaultSetupConfiguration;
  const [mainState, setMainState] = useState({});
  const [token, setToken] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);
  const [products, setProducts] = useState({});
  const [isScoreQueryPage, setIsScoreQueryPage] = useState(false);

  const [, updateAllState] = React.useState();
  const forceUpdate = React.useCallback(() => updateAllState({}), []);

  function updateState(key, data) {
    let thisState = mainState;
    if (mainState[key] !== data) {
      thisState[key] = data;
      setMainState(thisState);
      forceUpdate();
      // console.log("MS", mainState);
    }
  }

  const logoutWithRedirect = () => {
    setToken(null);
    setHasAccess(false);
    if (token) {
      localStorage.removeItem("productList_" + token.customerId);
    }
    let tokenName = setup.options.subProduct
      ? setup.options.subProduct.replace(/\s/g, "_").toLowerCase() +
        "_" +
        setup.options.productCode +
        "_tokenDetails"
      : setup.options.productCode + "_tokenDetails";
    localStorage.removeItem(tokenName);
    logout({
      returnTo: window.location.origin,
    });
  };

  function getMainClass() {
    if (isScoreQueryPage) {
      return "score-query-app";
    }

    return "mainbody";
  }

  return (
    <Router>
      <HelmetProvider>
        <Header
          title={title}
          login={loginWithRedirect}
          logout={logoutWithRedirect}
          isAuthenticated={isAuthenticated}
          hasAccess={hasAccess}
          userprofile={<UserProfile {...props} user={user} />}
          languageselect={
            <LanguageSelect setup={setup} locale={locale} language={language} />
          }
          hideMenuBuilder={isScoreQueryPage}
        />
        <Sidedrawer
          logout={logoutWithRedirect}
          isAuthenticated={isAuthenticated}
          hasAccess={hasAccess}
          products={products}
          logo={sideLogo}
        />
        {!loading && (
          <Container fluid={isScoreQueryPage} className={getMainClass()}>
            <Login
              login={loginWithRedirect}
              setup={setup}
              loading={loading}
              isAuthenticated={isAuthenticated}
              setToken={setToken}
              setHasAccess={setHasAccess}
              token={token}
              setProducts={setProducts}
            />
            {isAuthenticated && token && (
              <React.Fragment>
                <Route
                  exact
                  path="/"
                  render={() => <Redirect to="/organizations" />}
                />
                <Route
                  exact
                  path={"/organizations"}
                  render={(props) => {
                    setIsScoreQueryPage(false);
                    return (
                      <Organizations
                        {...props}
                        updateState={updateState}
                        title={title}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={"/organizations/:organizationId/:sport/credentials"}
                  render={(props) => {
                    setIsScoreQueryPage(false);
                    return (
                      <Credentials
                        {...props}
                        updateState={updateState}
                        title={title}
                        mainState={mainState}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/score-query"
                  render={(props) => {
                    setIsScoreQueryPage(true);
                    return <ScoreQuery />;
                  }}
                />
              </React.Fragment>
            )}
          </Container>
        )}
        <Footer setup={setup} />
      </HelmetProvider>
    </Router>
  );
};

export default App;
