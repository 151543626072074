import React, { useMemo } from "react";

import FormBuilder from "../Base/Forms/FormBuilder";
import formSetup from "./formSetup";

const UpdateCredentials = (props) => {
  const { data, reloadData, pageRef, setPasswordPanel, competitions, venues, entities } = props;

  function changeDone() {
    reloadData();
    setPasswordPanel(false);
  }

  const updateForm = useMemo(() => {
    if (data) {
      data.venues = venues;
      data.entities = entities;
      data.competitionList = competitions;
      let formName;
      switch (data.credentialType) {
        case "ENTITY":
          formName = "EditUserEntity";
          break;
        case "ALL":
          formName = "EditUserAll";
          break;
        case "VENUE":
          formName = "EditUserVenue";
          break;
        default:
          break;
      }
      return (
        <FormBuilder form={formName} action={changeDone} formSetup={formSetup} pageRef={pageRef} formData={data} />
      );
    }
    return null;
  }, [data]);

  return <React.Fragment>{updateForm}</React.Fragment>;
};

export default UpdateCredentials;
