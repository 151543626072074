import React from "react";
import { format } from "date-fns";
import { injectIntl } from "react-intl";

const dateFormatPattern = "dd/LL/yyyy HH:mm:ss";

function ScoreQueryNotificationLog(props) {
  const { notification, competitors, intl } = props;
  const { formatMessage } = intl;

  return (
    <>
      <li key={notification.id}>
        <p>
          {formatMessage({
            id: "sent",
            defaultMessage: "Sent",
          })}
          : {format(new Date(notification.added), dateFormatPattern)}
        </p>
        <p>
          {formatMessage({
            id: "response",
            defaultMessage: "Response",
          })}
          : {notification.response?.added ? format(new Date(notification.response.added), dateFormatPattern) : "N/A"}
        </p>
        {notification.notification_type === "score" && !!notification.response && (
          <div>
            <strong>
              {formatMessage({
                id: "currentScore",
                defaultMessage: "Current score",
              })}
            </strong>
            <p>
              {competitors[0].name}: {notification.response.scores[competitors[0].entityId]} <br />
              {competitors[1].name}: {notification.response.scores[competitors[1].entityId]}
            </p>
            <strong>
              {formatMessage({
                id: "prevScore",
                defaultMessage: "Previous score",
              })}
            </strong>
            <p>
              {competitors[0].name}: {notification.response.additional_data?.previousScore?.[competitors[0].entityId]}
              <br />
              {competitors[1].name}: {notification.response.additional_data?.previousScore?.[competitors[1].entityId]}
              <br />
            </p>
          </div>
        )}
        {notification.notification_type === "event" && (
          <div>
            <strong>
              {formatMessage({
                id: "details",
                defaultMessage: "Details",
              })}
            </strong>
            <ul>
              <li>
                {formatMessage({
                  id: "period",
                  defaultMessage: "Period",
                })}
                : {notification.metadata.periodId}
              </li>
              <li>
                {formatMessage({
                  id: "clock",
                  defaultMessage: "Clock",
                })}
                : {notification.metadata.clock}
              </li>
              <li>
                {formatMessage({
                  id: "eventType",
                  defaultMessage: "Event type",
                })}
                :{" "}
                {formatMessage({
                  id: `eventType.${notification.metadata.eventType}`,
                  defaultMessage: "Event Type",
                })}
              </li>
              <li>
                {formatMessage({
                  id: "team",
                  defaultMessage: "Team",
                })}
                : {competitors.find((competitor) => competitor.entityId === notification.metadata.entityId)?.name}
              </li>
              {notification.metadata.changeTo && (
                <li>
                  {formatMessage({
                    id: "changeTo",
                    defaultMessage: "Change to",
                  })}
                  :{" "}
                  {formatMessage({
                    id: `eventType.${notification.metadata.changeTo}`,
                    defaultMessage: "Event Type",
                  })}
                </li>
              )}
              {notification.metadata.comments && (
                <li>
                  {formatMessage({
                    id: "comments",
                    defaultMessage: "Comments",
                  })}
                  : {notification.metadata.comments}
                </li>
              )}
            </ul>
          </div>
        )}
      </li>
    </>
  );
}

export default injectIntl(ScoreQueryNotificationLog);
