const formSetup = {
  forms: {
    NewUserEntity: {
      title: "Add New Team User",
      titletranslation: "user.add.team",
      endpoint: "v1/#sport/organizations/#0/credentials",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New User added successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Add New User",
      submittranslation: "user.add",
      fields: [
        {
          name: "name",
          size: "col-sm-3 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "username",
          size: "col-sm-3 col",
          label: "User Name:",
          labeltranslation: "username",
          type: "text",
          placeholder: "User Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "User Name Is Required",
          },
        },
        {
          name: "password",
          size: "col-sm-3 col",
          label: "Password:",
          labeltranslation: "password",
          type: "text",
          placeholder: "Password",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Password Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,

          initial: "type",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-3 col",
          label: "Expiry Date:",
          labeltranslation: "date.expiry",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Expiry Date Is Required",
          },
        },
        {
          name: "competitions",
          size: "col-sm-6 col",
          label: "Competitions:",
          labeltranslation: "competitions",
          type: "multiSelect",
          placeholder: "Competitions",
          className: "form-control",
          required: true,
          initial: null,
          data: "competitions",
          textField: "name",
          valueField: "competitionId",
          errors: {
            required: "Competitions Is Required",
          },
        },
        {
          name: "ids",
          size: "col-sm-6 col",
          label: "Teams::",
          labeltranslation: "teams",
          type: "multiSelect",
          placeholder: "Teams",
          className: "form-control",
          required: true,
          initial: null,
          data: "entities",
          textField: "name",
          valueField: "entityId",
          errors: {
            required: "Teams Is Required",
          },
        },
      ],
    },
    NewUserVenue: {
      title: "Add New Venue User",
      titletranslation: "user.add.venue",
      endpoint: "v1/#sport/organizations/#0/credentials",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New User added successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Add New User",
      submittranslation: "user.add",
      fields: [
        {
          name: "name",
          size: "col-sm-3 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "username",
          size: "col-sm-3 col",
          label: "User Name:",
          labeltranslation: "username",
          type: "text",
          placeholder: "User Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "User Name Is Required",
          },
        },
        {
          name: "password",
          size: "col-sm-3 col",
          label: "Password:",
          labeltranslation: "password",
          type: "text",
          placeholder: "Password",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Password Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,

          initial: "type",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-3 col",
          label: "Expiry Date:",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Expiry Date Is Required",
          },
        },
        {
          name: "competitions",
          size: "col-sm-6 col",
          label: "Competitions:",
          labeltranslation: "competitions",
          type: "multiSelect",
          placeholder: "Competitions",
          className: "form-control",
          required: true,
          initial: null,
          data: "competitions",
          textField: "name",
          valueField: "competitionId",
          errors: {
            required: "Competitions Is Required",
          },
        },
        {
          name: "ids",
          size: "col-sm-6 col",
          label: "Venues::",
          labeltranslation: "venues",
          type: "multiSelect",
          placeholder: "Teams",
          className: "form-control",
          required: true,
          initial: null,
          data: "venues",
          textField: "name",
          valueField: "venueId",
          errors: {
            required: "Teams Is Required",
          },
        },
      ],
    },
    NewUserAll: {
      title: "Add New Super User",
      titletranslation: "user.add.super",
      endpoint: "v1/#sport/organizations/#0/credentials",
      parameters: ["organizationId"],
      method: "POST",
      successmessage: "New User added successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Add New User",
      submittranslation: "user.add",
      fields: [
        {
          name: "name",
          size: "col-sm-3 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "username",
          size: "col-sm-3 col",
          label: "User Name:",
          labeltranslation: "username",
          type: "text",
          placeholder: "User Name",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "User Name Is Required",
          },
        },
        {
          name: "password",
          size: "col-sm-3 col",
          label: "Password:",
          labeltranslation: "password",
          type: "text",
          placeholder: "Password",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Password Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,

          initial: "type",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-3 col",
          label: "Expiry Date:",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Expiry Date Is Required",
          },
        },
      ],
    },
    EditUserEntity: {
      title: "Update User",
      titletranslation: "user.update",
      endpoint: "v1/#sport/organizations/#0/credentials/#1",
      parameters: ["organizationId", "credentialId"],
      method: "PUT",
      successmessage: "User updated successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Update User",
      submittranslation: "user.update",
      fields: [
        {
          name: "name",
          size: "col-sm-6 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: "name",
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "username",
          size: "col-sm-3 col",
          label: "User Name:",
          labeltranslation: "username",
          type: "text",
          placeholder: "User Name",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "username",
          errors: {
            required: "User Name Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "credentialType",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-6 col",
          label: "Expiry Date:",
          labeltranslation: "date.expiry",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          initial: "expiryDate",
          errors: {
            required: "Expiry Date Is Required",
          },
        },
        {
          name: "competitions",
          size: "col-sm-6 col",
          label: "Competitions:",
          labeltranslation: "competitions",
          type: "multiSelect",
          placeholder: "Competitions",
          className: "form-control",
          required: false,
          initial: "competitions",
          data: "competitionList",
          textField: "name",
          valueField: "competitionId",
          errors: {
            required: "Competitions Is Required",
          },
        },
        {
          name: "ids",
          size: "col-sm-6 col",
          label: "Teams::",
          labeltranslation: "teams",
          type: "multiSelect",
          placeholder: "Teams",
          className: "form-control",
          required: false,
          initial: "ids",
          data: "entities",
          textField: "name",
          valueField: "entityId",
          errors: {
            required: "Teams Is Required",
          },
        },
      ],
    },
    EditUserVenue: {
      title: "Update User",
      titletranslation: "user.update",
      endpoint: "v1/#sport/organizations/#0/credentials/#1",
      parameters: ["organizationId", "credentialId"],
      method: "PUT",
      successmessage: "User updated successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Update User",
      submittranslation: "user.update",
      fields: [
        {
          name: "name",
          size: "col-sm-6 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: "name",
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "username",
          size: "col-sm-3 col",
          label: "User Name:",
          labeltranslation: "username",
          type: "text",
          placeholder: "User Name",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "username",
          errors: {
            required: "User Name Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "credentialType",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-6 col",
          label: "Expiry Date:",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          initial: "expiryDate",
          errors: {
            required: "Expiry Date Is Required",
          },
        },
        {
          name: "competitions",
          size: "col-sm-6 col",
          label: "Competitions:",
          labeltranslation: "competitions",
          type: "multiSelect",
          placeholder: "Competitions",
          className: "form-control",
          required: false,
          initial: "competitions",
          data: "competitionList",
          textField: "name",
          valueField: "competitionId",
          errors: {
            required: "Competitions Is Required",
          },
        },
        {
          name: "ids",
          size: "col-sm-6 col",
          label: "Venues::",
          labeltranslation: "venues",
          type: "multiSelect",
          placeholder: "Teams",
          className: "form-control",
          required: false,
          initial: "ids",
          data: "venues",
          textField: "name",
          valueField: "venueId",
          errors: {
            required: "Teams Is Required",
          },
        },
      ],
    },
    EditUserAll: {
      title: "Update User",
      titletranslation: "user.update",
      endpoint: "v1/#sport/organizations/#0/credentials/#1",
      parameters: ["organizationId", "credentialId"],
      method: "PUT",
      successmessage: "User updated successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Update User",
      submittranslation: "user.update",
      fields: [
        {
          name: "name",
          size: "col-sm-6 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          initial: "name",
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "credentialType",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-6 col",
          label: "Expiry Date:",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          initial: "expiryDate",
          errors: {
            required: "Expiry Date Is Required",
          },
        },
      ],
    },
    PasswordChange: {
      title: "Change Password",
      titletranslation: "password.change",
      endpoint: "v1/#sport/organizations/#0/credentials/#1",
      parameters: ["organizationId", "credentialId"],
      method: "PUT",
      successmessage: "Password updated successfully.",
      link: {
        show: false,
        message: "View Team",
        path: "/admin/teams/",
        parameters: [],
      },
      submit: "Update Password",
      submittranslation: "password.update",
      fields: [
        {
          name: "name",
          size: "col-sm-3 col",
          label: "Name:",
          labeltranslation: "name",
          type: "text",
          placeholder: "Name",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "name",
          errors: {
            required: "Name Is Required",
          },
        },
        {
          name: "username",
          size: "col-sm-3 col",
          label: "User Name:",
          labeltranslation: "username",
          type: "text",
          placeholder: "User Name",
          className: "form-control",
          required: true,
          hidden: true,

          initial: "username",
          errors: {
            required: "User Name Is Required",
          },
        },
        {
          name: "password",
          size: "col-sm-3 col",
          label: "Password:",
          labeltranslation: "password",
          type: "text",
          placeholder: "Password",
          className: "form-control",
          required: true,
          initial: null,
          errors: {
            required: "Password Is Required",
          },
        },
        {
          name: "credentialType",
          size: "col-sm-2 col",
          label: "Type:",
          type: "text",
          placeholder: "Type",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "credentialType",
          errors: {
            required: "Type Is Required",
          },
        },
        {
          name: "startDate",
          size: "col-sm-2 col",
          label: "startDate:",
          type: "text",
          placeholder: "startDate",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "startDate",
          errors: {
            required: "startDate Is Required",
          },
        },
        {
          name: "expiryDate",
          size: "col-sm-3 col",
          label: "Expiry Date:",
          labeltranslation: "date.expiry",
          type: "date",
          placeholder: "Expiry Date",
          className: "form-control",
          required: true,
          hidden: true,
          initial: "expiryDate",
          errors: {
            required: "Expiry Date Is Required",
          },
        },
        {
          name: "competitions",
          size: "col-sm-6 col",
          label: "Competitions:",
          labeltranslation: "competitions",
          type: "text",
          placeholder: "Competitions",
          className: "form-control",
          required: false,
          initial: "competitions",
          hidden: true,
          errors: {
            required: "Competitions Is Required",
          },
        },
        {
          name: "ids",
          size: "col-sm-6 col",
          label: "Teams::",
          labeltranslation: "teams",
          type: "text",
          placeholder: "Teams",
          className: "form-control",
          required: false,
          hidden: true,
          initial: "ids",
          errors: {
            required: "Teams Is Required",
          },
        },
      ],
    },
  },
};

export default formSetup;
